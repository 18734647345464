import { AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { compareTokenCreationWithCurrentDate } from 'utils/general.ts';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = () => {
	let token = localStorage.getItem('token');
	const location = useLocation();
	let notAuthenticated = false
	if (!token) {
		localStorage.clear();
		window.location = window.location.origin + '/auth/login';
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	} else {
		const decodedToken = jwtDecode(token);
		const isTokenExpired = compareTokenCreationWithCurrentDate(decodedToken?.iat);
		if (isTokenExpired) {
			localStorage.clear();
			notAuthenticated = true;
			window.location = window.location.origin + '/auth/login';
			return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
		}
	}
	return <Outlet />;
};

export default ProtectedRoute;
