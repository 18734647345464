import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';
import { baseTableFilter } from 'utils/general.ts';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
};

export const getAllTeamMember = createAsyncThunk('team/getAllTeamMember', async (filter, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(`/employee/list?sortBy=${filter?.sortBy ?? baseTableFilter.sortBy}&sortOrder=${filter?.sortOrder ?? baseTableFilter?.sortOrder ?? baseTableFilter.sortOrder}&page=${filter?.page ?? baseTableFilter.page ?? 1}&pageSize=${filter?.pageSize ?? baseTableFilter.pageSize}`, {
			role: filter.team,
			isActive: filter.status,
			branch: filter.branch,
			startDate: filter.startDate,
			endDate: filter.endDate,
			searchQuery: filter.search,
		});
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const getTeamMemberById = createAsyncThunk('team/getTeamMemberById', async (id, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.get(`/employee/${id}`);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const deleteTeamMember = createAsyncThunk('team/deleteTeamMember', async (id, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.delete(`/employee/delete/${id}`);
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const addTeamMember = createAsyncThunk('team/addTeamMember', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post('/employee/create', {
			name: data.name,
			email: data.email,
			phoneNo: data.phoneNo,
			password: data.password,
			role: data.role,
			branch: data.branch,
			target: data?.target ?? 0,
			incentive: data?.incentive ?? 0,
		});
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		let message = err?.response?.data?.message;
		return rejectWithValue(message || 'Error');
	}
});

export const editTeamMember = createAsyncThunk('team/editTeamMember', async (data, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.put(`/employee/update/${data.id}`, {
			name: data?.name ?? "",
			email: data.email,
			phoneNo: data.phoneNo,
			password: data.password,
			role: data.role,
			branch: data.branch,
			isActive: data.isActive,
			target: data?.target ?? 0,
			incentive: data?.incentive ?? 0,
		});
		if (response.success) {
			return response;
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error');
	}
});

export const teamMemberSlice = createSlice({
	name: 'team',
	initialState,
	reducers: {
		showLoading: (state) => {
			state.loading = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAllTeamMember.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllTeamMember.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getAllTeamMember.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(getTeamMemberById.pending, (state) => {
				state.loading = true;
			})
			.addCase(getTeamMemberById.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(getTeamMemberById.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(deleteTeamMember.pending, (state) => {
				state.loading = true;
			})
			.addCase(deleteTeamMember.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deleteTeamMember.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
});

export const { showLoading } = teamMemberSlice.actions;

export default teamMemberSlice.reducer;
