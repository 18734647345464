export const userType = {
    branchManager: "branch_manager",
    admin: "admin",
    salesAdviser: "sales_advisor",
    serviceAdviser: "service_advisor"
}

export const userTypeLabels = {
    [userType.admin]: "Admin",
    [userType.branchManager]: "Branch Manager",
    [userType.salesAdviser]: "Sales Advisor",
    [userType.serviceAdviser]: "Service Advisor"
}

export const appUserViews = {
    draft: "draft",
    bride: "bride",
    groom: "groom"
}

export const profileStatus = {
    pending: "pending",
    approved: "approved",
    rejected: "rejected",
    correction: "correction"
}

export const leadTypes = {
    assigned: {
        key: "assigned",
        label: "Assigned"
    },
    unassigned: {
        key: "unassigned",
        label: "Not Assigned"
    },
}

export const assignmentRef = {
    profile: "profile",
    lead: "lead"
}

export const defaultSTeamFilterState = {
    team: "",
    isActive: "",
    branch: "",
    // startDate: "",
    // endDate: "",
    searchQuery: "",
    status: "",
    pageSize: 100,
    page: 1,
    sortBy: "name"
}

export const paymentTypes = {
    upi: "upi",
    qrCode: "qrCode",
    card: "card",
    netBanking: "netBanking",
    cash: "cash"
}


export const paymentOptions = {
    [paymentTypes.upi]: {
        label: "UPI",
        values: [
            {
                name: "Google Pay (Mobile)"
            },
            {
                name: "Phone Pay (Mobile)"
            },
            {
                name: "Paytm (Mobile)"
            },
            {
                name: "Google Pay (QR Code)"
            },
            {
                name: "Phone Pay (QR Code)"
            },
            {
                name: "Paytm (QR Code)"
            }
        ]
    },
    [paymentTypes.card]: {
        label: "Card",
        values: [
            {
                name: "Debit Card"
            },
            {
                name: "Credit Card"
            }
        ]
    },
    [paymentTypes.netBanking]: {
        label: "Net Banking",
        values: [
            {
                name: "NEFT"
            },
            {
                name: "RTGS"
            },
            {
                name: "IMPS"
            },
            {
                name: "Bank Transfer"
            }
        ]
    },
    [paymentTypes.cash]: {
        label: "Cash",
        values: [
            {
                name: "cash"
            }
        ]
    }
}

export const getPaymentOptions = () => {
    return Object.entries(paymentOptions).map(([key, { label, values }]) => ({
        label: label,
        title: label,
        options: values.map(({ name }) => ({
            label: name,
            value: name
        }))
    }));
}

export const allFilters = {
    dateRange: {
        show: true
    },
    profileReviewStatus: {
        show: true
    },
    branch: {
        show: true
    },
    salesAdvisor: {
        show: true
    },
    refLeadSource: {
        show: true
    },
    leadType: {
        show: true
    },
    leadStatusType: {
        show: true
    },
    paymentMethod: {
        show: true
    },
    subscriptionPlan: {
        show: true
    }
}

export const roleBasedProfileDrawerFilters = {
    [userType.admin]: {
        ...allFilters,
        paymentMethod: { show: false },
        subscriptionPlan: { show: false }
    }
}

export const defaultEmptyValue = "-"