import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'api/axios';
import * as mergers from '../mergers'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
};

export const getLeadStatusCount = createAsyncThunk('analytics/getLeadStatusCount', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        // empId: filter.empId,
        createdBy: filter.empId
    }
    try {
        const response = await axiosInstance.post(`/dashboard/lead-status-counts`, rBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getSubscriptionSoldCount = createAsyncThunk('analytics/getSubscriptionSoldCount', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId
    }
    try {
        const response = await axiosInstance.post(`/dashboard/subscription-sold-counts`, rBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getCallsMadeCount = createAsyncThunk('analytics/getCallsMadeCount', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empIde,
        // empId: filter.empId
    }
    try {
        const response = await axiosInstance.post(`/dashboard/calls-made-counts`, rBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getLeadAssignedCount = createAsyncThunk('analytics/getLeadAssignedCount', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        //  empId: filter.empId


    }
    try {
        const response = await axiosInstance.post(`/dashboard/lead-assigned-counts`, rBody);
        if (response.success) {
            return response;
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});


export const getLeadBaseCounts = createAsyncThunk('analytics/getLeadBaseCounts', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId

    }
    try {
        const response = await axiosInstance.post(`/dashboard/lead-base-counts`, rBody);
        response.data = await mergers.mergeBaseCounts(response?.data);
        if (response.success) {
            return response
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getSalesAdvisorCounts = createAsyncThunk('analytics/getSalesAdvisorCounts', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId

    }
    try {
        const response = await axiosInstance.post(`/dashboard/sales-advisor-counts`, rBody);
        if (response.success) {
            return response
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getSalesAdvisorTotalCount = createAsyncThunk('analytics/getSalesAdvisorTotalCount', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId

    }
    try {
        const response = await axiosInstance.post(`/dashboard/sales-total-counts`, rBody);
        if (response.success) {
            return response
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getProfileBasedSalesTargetDetails = createAsyncThunk('analytics/getProfileBasedSalesTargetDetails', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId

    }
    try {
        const response = await axiosInstance.post(`/dashboard/sales-target-details-user-profiles-status`, rBody);
        if (response.success) {
            return response
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const getSalesTargetDetails = createAsyncThunk('analytics/getSalesTargetDetails', async (filter, { rejectWithValue }) => {
    const rBody = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        branch: filter.branch,
        createdBy: filter.empId,
        // empId: filter.empId

    }
    try {
        const response = await axiosInstance.post(`/dashboard/sales-target-details`, rBody);
        if (response.success) {
            return response
        } else {
            return rejectWithValue(response.message?.replace('Firebase: ', ''));
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
    },
});

export const { showLoading } = analyticsSlice.actions;

export default analyticsSlice.reducer;
